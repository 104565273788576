import {hasEventDetailsUrl, isEventRestricted} from '../../selectors/events'
import {AppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {ButtonOwnProps, ButtonStateProps} from './interfaces'
import {RsvpButton as RsvpButtonPresentation} from './rsvp-button'

const mapRuntime = ({state, actions}: AppProps, {event}: ButtonOwnProps): ButtonStateProps => ({
  navigateToPage: () => actions.navigateToPage(event),
  hasUrl: hasEventDetailsUrl(state, event),
  eventRestricted: isEventRestricted(state, event),
})

export const RsvpButton = connect<ButtonOwnProps, ButtonStateProps>(mapRuntime)(RsvpButtonPresentation)
