import {ExperimentNames} from '@wix/wix-events-commons-statics'
import {widgetDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useExperiments} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {useSettings} from '../../../hooks/use-settings'
import settingsParams from '../../../settingsParams'
import {useVisibilityStyles} from '../../hooks/use-visibility-styles'
import sc from '../classes.scss'
import {MainListTitleProps} from './interfaces'
import s from './main-list-title.scss'

export const MainListTitle = ({listLayout, mobile, cardsLayout}: MainListTitleProps) => {
  const {get} = useSettings()
  const {
    allBreakpoints: {isListMainTitleEnabled},
  } = useVisibilityStyles()
  const {experiments} = useExperiments()

  const title = get(settingsParams.listGeneralTitle)
  const widgetListTitleUpdatesEnabled = experiments.enabled(ExperimentNames.WidgetListTitleUpdates)

  return isListMainTitleEnabled() ? (
    <h1
      className={classNames(s.title, sc.textLineHeight, {
        [s.mobile]: !widgetListTitleUpdatesEnabled && mobile,
        [s.cards]: !widgetListTitleUpdatesEnabled && cardsLayout,
        [s.listLayout]: !widgetListTitleUpdatesEnabled && listLayout,
        [s.defaultLayout]: !widgetListTitleUpdatesEnabled && !mobile && !listLayout && !cardsLayout,
        [s.desktopLayout]: widgetListTitleUpdatesEnabled && !mobile,
        [s.mobileLayout]: widgetListTitleUpdatesEnabled && mobile,
      })}
      data-hook={DH.listTitle}
    >
      {title}
    </h1>
  ) : null
}
